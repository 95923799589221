import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  Modal,
  TextInput,
  PanResponder,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import { CheckBox } from "react-native-elements";
import { BottomMenu } from "../../components/Menu";
// import Barcode from 'react-native-barcode-expo';
import { MycaAPI } from "../../lib/function";
import Svg, { G, Path } from "react-native-svg";
import { MycaButton, ColorPrice } from "./../../components/Common";
import { useFocusEffect } from "@react-navigation/native";
import { useGlobalNotify } from "../../contexts/GlobalNotify";

const commonStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgba(248,248,248,1)",
    position: "relative",
    paddingBottom: "18%",
  },
  title: {
    backgroundColor: MycaAPI.style.ThemeColor,
    width: "100%",
    padding: 10,
  },
  TermsTitle: {
    backgroundColor: MycaAPI.style.ThemeColor,
    width: "90%",
    padding: 10,
    marginTop: 10,
    marginLeft: "5%",
  },
  titleText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
    color: "white",
  },
  //査定状況を表示する部分
  statusContainer: {
    width: "100%",
    height: "15%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  statusText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 40,
  },
  statusLabel: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 12,
    color: MycaAPI.style.ThemeColor,
  },
  //査定結果を表示する部分
  assessmentOngoingContainer: {
    width: "100%",
    height: "75%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  assessmentResultsContainer: {
    width: "100%",
    height: "70%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
  },
  boldText: {
    fontWeight: "bold",
    textAlign: "center",
  },
  //確定ボタン
  decisionBtn: {
    backgroundColor: MycaAPI.style.ThemeColor,
    borderRadius: 5,
    paddingHorizontal: 30,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  decisionBtnText: {
    color: "white",
    fontSize: 14,
  },

  //規約確認画面のスタイル
  termsContainer: {
    width: "90%",
    height: "60%",
    marginLeft: "5%",
    marginTop: 10,
    backgroundColor: "#f5f5f5",
    borderRadius: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "rgb(132, 132, 132)",
  },
  checkBoxContainer: {
    flexDirection: "row",
    width: "80%",
    alignItems: "center",
    marginLeft: "10%",
  },
  checkBoxText: {
    fontWeight: "bold",
    width: "80%",
    flexWrap: "wrap",
  },
});

const renderItemStyle = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 80,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
  },
  image: {
    width: "15%",
    aspectRatio: 0.71,
  },
  itemDetails: {
    flex: 1,
    height: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
    marginLeft: "3%",
    marginRight: "3%",
  },
  itemName: {
    fontWeight: "bold",
    fontSize: 14,
  },
  smallText: {
    fontSize: 12,
    color: "#757575", //灰色を設定したい
  },
  itemPriceContainer: {
    width: "30%",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
  },
  totalPrice: {
    fontWeight: "bold",
    fontSize: 20,
  },
  //合計金額を表示する場所
  listHeader: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#f5f5f5",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 16,
  },
  editBtn: {
    backgroundColor: MycaAPI.style.ThemeColor,
    width: "100%",
    borderRadius: 5,
    padding: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  editBtnText: {
    color: "white",
    fontSize: 10,
  },
});

//買取棄却を確認する画面
export function PurchaseTermsScreen({ API, route, CurrentGenre, CurrentMenu }) {
  const [buyTerm, setBuyTerm] = useState(""); //規約文

  useFocusEffect(
    useCallback(() => {
      (async () => {
        //reception情報を確認しつつ、同意済みだったら飛ばす
        const receptionInfo = route?.params?.receptionInfo;

        if (!receptionInfo) return false;

        if (receptionInfo.term_accepted_at) {
          //飛ばす
          navigateToSatei();
        } else {
          //そうではない場合は規約文をストアから取得する
          const thisStoreInfo = await API.getPosStoreInfo(
            receptionInfo.store_id,
          );

          if (!thisStoreInfo) {
            alert("エラーが発生：ストア情報が取得できません");
            return false;
          }

          setBuyTerm(thisStoreInfo.buy_term);
        }
      })();
    }, [route?.params?.receptionInfo]),
  );

  //ショップ査定ページに遷移させる
  const navigateToSatei = () => {
    API.navigation.replace("ショップ査定ページ", {
      receptionInfo: route?.params?.receptionInfo,
    });
  };

  const { registerNotifyInfo } = useGlobalNotify();

  //同意しつつ、遷移
  const handleSubmit = async () => {
    const receptionInfo = route?.params?.receptionInfo;

    //同意する
    const updateRes = await API.putPosTransaction(receptionInfo.id, {
      term_accepted_at: new Date(),
    });

    if (updateRes?.id) {
      //査定済みにする
      //ここではダミーでとりあえず今のDate
      registerNotifyInfo("purchaseReception", {
        ...receptionInfo,
        term_accepted_at: new Date(),
      });
    }

    navigateToSatei();
  };

  const [agreements, setAgreements] = useState({
    termsAgreement: false,
    taxAgreement: false,
  });

  // チェックボックスの状態を管理
  const handleAgreementChange = (field) => {
    setAgreements((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <View style={commonStyles.container}>
      <BottomMenu {...{ CurrentGenre, CurrentMenu, API }} />
      <View style={commonStyles.TermsTitle}>
        <Text style={commonStyles.titleText}>買取における注意事項</Text>
      </View>

      <View style={commonStyles.termsContainer}>
        <ScrollView>
          <Text>{buyTerm}</Text>
        </ScrollView>
      </View>

      <View style={commonStyles.checkBoxContainer}>
        <CheckBox
          checked={agreements.termsAgreement}
          onPress={() => handleAgreementChange("termsAgreement")}
          checkedColor="rgb(184, 42, 42)"
        />
        <Text style={commonStyles.checkBoxText}>
          上記規約をすべて読んで同意します
        </Text>
      </View>

      <View style={[commonStyles.checkBoxContainer, { marginBottom: 10 }]}>
        <CheckBox
          checked={agreements.taxAgreement}
          onPress={() => handleAgreementChange("taxAgreement")}
          checkedColor="rgb(184, 42, 42)"
        />
        <Text style={commonStyles.checkBoxText}>
          私(売り主)は消費税における適格請求業者ではありません。
        </Text>
      </View>

      <MycaButton
        onPress={handleSubmit}
        disabled={!agreements.taxAgreement || !agreements.termsAgreement}
        color="red"
      >
        査定状況確認
      </MycaButton>
    </View>
  );
}

//ショップの査定の結果や変更などを確認するスクリーン
export function SateiScreen({ API, route, CurrentGenre, CurrentMenu }) {
  //査定状況を管理

  const [transactionInfo, setTransactionInfo] = useState({});

  //スクリーンが呼び出されるごとに取引の詳細を取得する
  useFocusEffect(
    useCallback(async () => {
      //査定が終わっていたら情報を取得する
      if (
        route.params?.receptionInfo?.id &&
        route.params?.receptionInfo?.assessed
      ) {
        //取引の詳細情報を取得する
        const detailInfo = await API.getPosTransactionDetail(
          route.params?.receptionInfo?.id,
        );

        const updatedDetailInfo = {
          ...detailInfo,
          transaction_carts: detailInfo.transaction_carts.map((cart) => ({
            ...cart,
            max_item_count: cart.item_count, // item_countをmax_item_countに複製
          })),
        };

        console.log(updatedDetailInfo);

        // stateに更新済みの情報を保存
        setTransactionInfo(updatedDetailInfo);
      }
    }, []),
  );

  //数量変更モーダル
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  //確認モーダル
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  //署名モーダル
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);

  //数量変更モーダル
  const openModal = (item) => {
    setSelectedItem({
      ...item,
    });
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
    setSelectedItem(null);
  };

  // 確認モーダルを開く
  const openConfirmModal = () => {
    setConfirmModalVisible(true);
  };

  // 確認モーダルを閉じる
  const closeConfirmModal = () => {
    setConfirmModalVisible(false);
  };

  // 署名モーダルを開く
  const openSignatureModal = () => {
    setSignatureModalVisible(true);
  };

  // 署名モーダルを閉じる
  const closeSignatureModal = () => {
    setSignatureModalVisible(false);
  };

  // 確定処理：確認モーダルの確定ボタンを押したら署名モーダルを開く
  const handleConfirm = () => {
    setConfirmModalVisible(false);
    openSignatureModal(); // 署名モーダルを開く
  };

  //数量変更
  const handleConfirmQuantityChange = useCallback(
    async (newQuantity) => {
      if (selectedItem) {
        setTransactionInfo((prev) => {
          const updatedItems = prev.transaction_carts.map((item) =>
            item.product__id == selectedItem.product__id &&
            item.unit_price == selectedItem.unit_price
              ? { ...item, item_count: newQuantity }
              : item,

          );

          const newTotalPrice = updatedItems.reduce((curSum, e) => {
            const unitPrice =
              (e.unit_price || 0) +
              (e.discount_price || 0) +
              (e.sale_discount_price || 0);

            return curSum + unitPrice * e.item_count;
          }, 0);

          //数量変更をPOSに送信する

          API.putPosTransactionCustomerCart(
            transactionInfo.id,
            updatedItems.map((cart) => ({
              product_id: cart.product__id,
              item_count: cart.item_count,
              unit_price: cart.unit_price,
              discount_price: cart.discount_price,
            })),
          ).then((res) => console.log("数量変更が送信されました", res));

          return {
            ...prev,
            total_price: newTotalPrice,
            transaction_carts: updatedItems,
          };
        });
      }
    },
    [transactionInfo, selectedItem],
  );

  const renderItem = useCallback(({ item }) => {
    item.totalUnitPrice =
      (item.unit_price || 0) +
      (item.discount_price || 0) +
      (item.sale_discount_price || 0);

    return (
      <View style={renderItemStyle.itemContainer}>
        <Image
          source={{
            uri: item.product__image_url,
          }}
          resizeMode="contain"
          style={renderItemStyle.image}
        />
        <View style={renderItemStyle.itemDetails}>
          <Text style={renderItemStyle.itemName}>
            {item.product__display_name}
          </Text>
          <Text style={renderItemStyle.smallText}>
            状態:{" "}
            {item.product__conditions
              ? item.product__conditions[0].condition_option__display_name
              : null}
          </Text>
          <View style={{ flexDirection: "row" }}>
            <Text style={[renderItemStyle.smallText, { marginRight: 10 }]}>
              ￥{item.totalUnitPrice?.toLocaleString()}
            </Text>
            <Text style={renderItemStyle.smallText}>
              数量: {item.item_count}
            </Text>
          </View>
        </View>
        <View style={renderItemStyle.itemPriceContainer}>
          <TouchableOpacity
            style={renderItemStyle.editBtn}
            onPress={() => openModal(item)}
          >
            <Text style={renderItemStyle.editBtnText}>枚数変更</Text>
          </TouchableOpacity>
          <Text style={renderItemStyle.totalPrice}>
            ¥{(item.totalUnitPrice * item.item_count).toLocaleString()}
          </Text>
        </View>
      </View>
    );
  }, []);

  return (
    <View style={commonStyles.container}>
      <BottomMenu {...{ CurrentGenre, CurrentMenu, API }} />
      <View style={commonStyles.title}>
        <Text style={commonStyles.titleText}>買取待ち状況</Text>
      </View>

      <View style={commonStyles.statusContainer}>
        {!route.params?.receptionInfo?.assessed ? (
          <Text style={commonStyles.statusText}>査定中</Text>
        ) : (
          <>
            <Text
              style={[
                commonStyles.statusText,
                { color: MycaAPI.style.ThemeColor },
              ]}
            >
              査定完了
            </Text>
            <Text style={commonStyles.statusLabel}>査定内容をご確認の上</Text>
            <Text style={commonStyles.statusLabel}>レジまでお越しください</Text>
          </>
        )}
      </View>

      {!route.params?.receptionInfo?.assessed ? (
        <View style={commonStyles.assessmentOngoingContainer}>
          <Text style={[commonStyles.boldText, { fontSize: 14 }]}>
            お客様の買取番号
          </Text>
          <Text style={[commonStyles.boldText, { fontSize: 150 }]}>
            {route.params?.receptionInfo?.receptionNumber}
          </Text>
        </View>
      ) : (
        <View style={commonStyles.assessmentResultsContainer}>
          <Text style={[commonStyles.boldText, { fontSize: 20 }]}>
            買取番号
            <Text style={[commonStyles.boldText, { fontSize: 40 }]}>
              {transactionInfo.reception_number}
            </Text>
          </Text>

          <View style={renderItemStyle.listHeader}>
            <Text style={renderItemStyle.headerText}>査定内容</Text>
            <Text style={renderItemStyle.headerText}>
              合計査定額 ¥{transactionInfo.total_price?.toLocaleString()}
            </Text>
          </View>

          <FlatList
            data={transactionInfo.transaction_carts || []}
            keyExtractor={(item, index) => index.toString()}
            style={{ width: "100%" }}
            contentContainerStyle={{ paddingHorizontal: 20 }}
            renderItem={renderItem}
          />

          <TouchableOpacity
            style={commonStyles.decisionBtn}
            onPress={openConfirmModal}
          >
            <Text style={commonStyles.decisionBtnText}>
              査定に同意して買取を確定する
            </Text>
          </TouchableOpacity>
        </View>
      )}

      <QuantityModal
        visible={modalVisible}
        item={selectedItem}
        onClose={closeModal}
        onConfirm={handleConfirmQuantityChange}
      />

      {/* 確認モーダル */}
      <ConfirmModal
        visible={confirmModalVisible}
        onConfirm={handleConfirm} // 確定ボタンを押したとき
        onClose={closeConfirmModal} // 戻るボタンを押したとき
      />

      {/* 署名モーダル */}
      <SignatureModal
        visible={signatureModalVisible}
        onClose={closeSignatureModal}
        transactionInfo={transactionInfo}
        API={API}
      />
    </View>
  );
}

//数量変更モーダル
export function QuantityModal({ visible, onClose, item, onConfirm }) {
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    setQuantity(item?.item_count);
  }, [item?.item_count]);

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) =>
      Math.min(item.max_item_count, Math.max(0, prevQuantity + change))
    ); 
  };

  const handleConfirm = () => {
    onConfirm(quantity);
    onClose();
  };

  return (
    <Modal transparent={true} visible={visible} onRequestClose={onClose}>
      <View style={modalStyle.modalContainer}>
        <View style={modalStyle.modalContent}>
          <View style={modalStyle.modalHeader}>
            <Text style={modalStyle.modalTitle}>買取枚数変更</Text>
          </View>
          {item && (
            <>
              <View style={modalStyle.modalBody}>
                <Image
                  source={{
                    uri: item.product__image_url,
                  }}
                  resizeMode="contain"
                  style={modalStyle.modalImage}
                />
                <View style={modalStyle.modalInfo}>
                  <Text style={modalStyle.modalText}>
                    {item.product__display_name}
                  </Text>
                  <Text style={modalStyle.modalText}>
                    状態:{" "}
                    {item.product__conditions
                      ? item.product__conditions[0]
                          .condition_option__display_name
                      : null}
                  </Text>
                  <Text style={modalStyle.modalText}>
                    査定額: ¥{item.totalUnitPrice?.toLocaleString()}
                  </Text>
                </View>
              </View>

              <View style={modalStyle.quantityContainer}>
                <Text style={modalStyle.quantityLabel}>買取数</Text>

                <TouchableOpacity
                  onPress={() => handleQuantityChange(-1)}
                  style={[
                    modalStyle.quantityBtn,
                    quantity == 0 && modalStyle.quantityBtnDisabled,
                  ]}
                >
                  <Text style={[modalStyle.quantityText]}>-</Text>
                </TouchableOpacity>
                <TextInput
                  style={modalStyle.quantityInput}
                  value={String(quantity)}
                  keyboardType="numeric"
                  onChangeText={(text) => setQuantity(parseInt(text) || 1)}
                />
                <TouchableOpacity
                  onPress={() => handleQuantityChange(1)}
                  style={[
                    modalStyle.quantityBtn,
                    quantity == item?.item_count &&
                      modalStyle.quantityBtnDisabled,
                  ]}
                >
                  <Text style={modalStyle.quantityText}>+</Text>
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                style={modalStyle.confirmBtn}
                onPress={handleConfirm}
              >
                <Text style={modalStyle.confirmBtnText}>確定</Text>
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>
    </Modal>
  );
}

//確認モーダル(署名前)
export function ConfirmModal({ visible, onConfirm, onClose }) {
  return (
    <Modal transparent={true} visible={visible} onRequestClose={onClose}>
      <View style={modalStyle.modalContainer}>
        <View style={modalStyle.modalContent}>
          <View style={modalStyle.modalHeader}>
            <Text style={modalStyle.modalTitle}>査定内容確定</Text>
          </View>
          <View style={modalStyle.signatureModalBody}>
            <Image
              style={modalStyle.ended_check}
              source={require("../../assets/dangerous_icon.png")}
            />
            <Text
              style={[
                modalStyle.modalText,
                { marginTop: 20, fontWeight: "bold" },
              ]}
            >
              確定後は変更ができません。
            </Text>
            <Text style={[modalStyle.modalText, { fontWeight: "bold" }]}>
              本当によろしいですか？
            </Text>
          </View>

          <View style={modalStyle.btnColumnContainer}>
            <MycaButton
              color="red"
              onPress={onConfirm} // 確定ボタンの処理
            >
              確定
            </MycaButton>
            <MycaButton
              color="gray"
              onPress={onClose} // 戻るボタンの処理
              paddingTop={0}
            >
              戻る
            </MycaButton>
          </View>
        </View>
      </View>
    </Modal>
  );
}

//署名モーダル
export function SignatureModal({ visible, onClose, API, transactionInfo }) {
  const [signature, setSignature] = useState([]);
  const [paths, setPaths] = useState([]);
  const [isSignatureEntered, setIsSignatureEntered] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  //署名を記録する処理
  //react-native-signature-canvasが使いえないためsvgで対応
  const pointsToSvg = (points) => {
    const distanceThreshold = 40;
    const filteredPoints = points.filter((point, index) => {
      if (!points[index - 1]) return true;
      const distance = Math.sqrt(
        Math.pow(points[index - 1].x - point.x, 2) +
          Math.pow(points[index - 1].y - point.y, 2),
      );
      return distance < distanceThreshold;
    });

    if (!filteredPoints.length) {
      return "";
    }

    let path = `M ${filteredPoints[0].x},${filteredPoints[0].y}`;
    for (let point of filteredPoints) {
      path = `${path} L ${point.x},${point.y}`;
    }

    return path;
  };

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponder: () => true,
    onPanResponderGrant: (event) => {
      setSignature([
        { x: event.nativeEvent.locationX, y: event.nativeEvent.locationY },
      ]);
      setIsSignatureEntered(true);
    },
    onPanResponderMove: (event) => {
      setSignature([
        ...signature,
        { x: event.nativeEvent.locationX, y: event.nativeEvent.locationY },
      ]);
    },
    onPanResponderRelease: () => {
      setPaths([...paths, { d: pointsToSvg(signature) }]);
      setSignature([]);
    },
  });

  const svgRef = useRef();

  const [processStatus, setProcessStatus] = useState("");

  const { registerNotifyInfo } = useGlobalNotify();

  //確定ボタン
  const handleConfirm = async () => {
    setProcessStatus("署名アップロード中");

    //ここで署名をアップロードする
    if (svgRef.current) {
      svgRef.current.toDataURL(async (dataUrl) => {
        if (dataUrl) {
          const dataUri = "data:image/png;base64," + dataUrl;

          const uploadRes = await API.uploadImage(
            "transaction",
            dataUri,
            "signature.png",
          );

          setProcessStatus("取引情報を更新中");

          //この署名をトランザクションに結びつける
          const updateResult = await API.putPosTransaction(transactionInfo.id, {
            signature_image_url: uploadRes.imageUrl,
          });

          if (updateResult.id != transactionInfo.id)
            setProcessStatus("エラーが発生しました");

          //通知情報をリセットする
          registerNotifyInfo("purchaseReception");
          setIsConfirmed(true);
          setProcessStatus("");
        }
      });
    }
  };

  const handleClose = () => {
    setSignature([]);
    setPaths([]);
    onClose();
    setIsConfirmed(false);
    API.navigation.navigate("トップページ");
  };

  return (
    <Modal transparent={true} visible={visible} onRequestClose={onClose}>
      <View style={modalStyle.modalContainer}>
        <View style={modalStyle.modalContent}>
          <View style={modalStyle.modalHeader}>
            <Text style={modalStyle.modalTitle}>買取確認</Text>
          </View>

          {isConfirmed ? (
            <View style={modalStyle.modalContent}>
              <Text
                style={[
                  modalStyle.boldModalText,
                  { color: MycaAPI.style.ThemeColor, marginTop: 20 },
                ]}
              >
                買取完了
              </Text>
              <Text
                style={[
                  modalStyle.boldModalText,
                  ,
                  { marginTop: 20, marginBottom: 20 },
                ]}
              >
                Thank You !!
              </Text>
              <MycaButton color="red" onPress={handleClose}>
                トップページに戻る
              </MycaButton>
            </View>
          ) : (
            <>
              <View style={modalStyle.signatureModalBody}>
                <Text
                  style={[{ color: "black", fontSize: 18, fontWeight: "bold" }]}
                >
                  買取番号　{transactionInfo.reception_number}
                </Text>
                <Text
                  style={[
                    modalStyle.boldModalText,
                    { color: MycaAPI.style.ThemeColor },
                  ]}
                >
                  この画面を店員に
                </Text>
                <Text
                  style={[
                    modalStyle.boldModalText,
                    { color: MycaAPI.style.ThemeColor },
                  ]}
                >
                  お見せください
                </Text>
                <Text
                  style={[
                    modalStyle.modalText,
                    { marginTop: 10, fontWeight: "bold" },
                  ]}
                >
                  確定ボタンを押した後は買取の
                </Text>
                <Text style={[modalStyle.modalText, { fontWeight: "bold" }]}>
                  取り消し、変更ができません。
                </Text>
                <Text
                  style={[
                    modalStyle.modalText,
                    { marginTop: 10, fontWeight: "bold" },
                  ]}
                >
                  上記内容に同意しました。
                </Text>
                {Boolean(processStatus) && (
                  <View
                    style={{
                      marginTop: 10,
                      gap: 5,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text>{processStatus}</Text>
                    {!processStatus.includes("エラー") && (
                      <ActivityIndicator size="small" color="#b82a2a" />
                    )}
                  </View>
                )}
              </View>

              <View style={modalStyle.signatureContainer}>
                <Text style={modalStyle.modalText}>ご署名</Text>

                <View
                  style={modalStyle.signatureInputContainer}
                  {...panResponder.panHandlers}
                >
                  <Svg
                    width="100%"
                    height="100%"
                    preserveAspectRatio="none"
                    ref={svgRef}
                  >
                    <G>
                      {paths.map((path, index) => (
                        <Path
                          key={index}
                          d={path.d}
                          stroke="black"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fill="none"
                        />
                      ))}
                      <Path
                        d={pointsToSvg(signature)}
                        stroke="red"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="4"
                        fill="none"
                      />
                    </G>
                  </Svg>
                </View>
              </View>

              <Text
                style={[
                  modalStyle.modalText,
                  { color: MycaAPI.style.ThemeColor },
                ]}
              >
                ※お客様による買取内容の変更があります。
              </Text>

              <View style={modalStyle.btnContainer}>
                <MycaButton
                  color="gray"
                  onPress={() => handleClose()}
                  paddingLeft={30}
                  paddingRight={30}
                >
                  戻る
                </MycaButton>
                {isSignatureEntered && (
                  <MycaButton
                    color="red"
                    onPress={handleConfirm}
                    paddingLeft={30}
                    paddingRight={30}
                  >
                    確定
                  </MycaButton>
                )}
              </View>
            </>
          )}
        </View>
      </View>
    </Modal>
  );
}

const modalStyle = StyleSheet.create({
  //共有のものと数量変更モーダル
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", //色統一？
  },
  modalContent: {
    width: "90%",
    backgroundColor: "white",
    borderRadius: 10,
    alignItems: "center",
  },
  modalHeader: {
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    backgroundColor: MycaAPI.style.ThemeColor,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    width: "100%",
  },
  modalTitle: {
    color: "white",
    fontSize: 15,
    fontWeight: "bold",
  },
  modalBody: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: 20,
  },
  modalImage: {
    width: "25%",
    aspectRatio: 0.71,
  },
  modalInfo: {
    width: "70%",
    marginLeft: "5%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  modalText: {
    fontSize: 14,
  },
  quantityContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  quantityLabel: {
    fontSize: 12,
    marginRight: 10,
  },
  quantityBtn: {
    width: 20,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: 3,
  },
  quantityBtnDisabled: {
    opacity: 0.4,
  },
  quantityText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "white",
  },

  quantityInput: {
    width: 40,
    textAlign: "center",
    fontSize: 30,
    fontWeight: "bold",
    marginHorizontal: 10,
  },
  confirmBtn: {
    width: "30%",
    paddingVertical: 10,
    backgroundColor: MycaAPI.style.ThemeColor,
    borderRadius: 5,
    alignItems: "center",
    marginBottom: 20,
  },
  confirmBtnText: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold",
  },

  //署名モーダルで使用するデザイン
  signatureModalBody: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: 20,
  },
  boldModalText: {
    fontSize: 22,
    fontWeight: "bold",
  },
  signatureContainer: {
    width: "100%",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  signatureInputContainer: {
    width: "100%",
    height: 100,
    borderWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "#fff",
  },
  btnContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingHorizontal: 20,
  },
  btnColumnContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingHorizontal: 20,
  },
  ended_check: {
    width: 113,
    height: 100,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 30,
  },
});
